/* Cascading Stylesheet for vhw - screen */
/* 3pc GmbH Neue Kommunikation: Philipp Veller - 04.09.2014 */
/* Changes: 3pc GmbH Neue Kommunikation: Jasmina Liessmann - 06.01.2015 */
/* PALETTE:
	dark-blue: #0080bc
	breadcrumb-blue: #7fabcf
	mid-blue: #83b0d4
	light-blue: #d8ecfe
	light-grey: #999999
	dark-grey: #4e4e4e
	footer light-blue: #1968a8
*/
/*=============================
=            Fonts            =
=============================*/
@font-face {
  font-family: 'Iconfont';
  font-style: normal;
  font-weight: normal;
  src: url('../Fonts/iconfont/vhw_icons.eot');
  /* IE9 Compat Modes */
  src: url('../Fonts/iconfont/vhw_icons.eot?#iefix') format('embedded-opentype'), url('../Fonts/iconfont/vhw_icons.woff') format('woff');
}
@font-face {
  font-family: 'DIN Pro';
  font-stretch: normal;
  font-style: normal;
  font-weight: 400;
  src: url('../Fonts/dinpro/DINPro-Regular.eot');
  src: url('../Fonts/dinpro/DINPro-Regular.eot?#iefix') format('embedded-opentype'), url('../Fonts/dinpro/DINPro-Regular.otf') format('opentype');
}
@font-face {
  font-family: 'DIN Pro';
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  src: url('../Fonts/dinpro/DINPro-Medium.eot');
  src: url('../Fonts/dinpro/DINPro-Medium.eot?#iefix') format('embedded-opentype'), url('../Fonts/dinpro/DINPro-Medium.otf') format('opentype');
}
/*=====  Iconfont  ======*/
.icon {
  color: #FFF;
  font-family: 'Iconfont' !important;
  text-transform: lowercase;
}
h2 span.mail:before {
  vertical-align: top;
  content: 'm';
  font-size: 16px;
}
section p.error:before {
  vertical-align: top;
  content: 'w';
  font-family: 'Iconfont';
}
section .search,
header .search {
  position: relative;
}
section .search:after,
header .search:after {
  color: #0080bc;
  content: 's';
  font-family: 'Iconfont' !important;
  font-size: 13px;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
}
section .search > input,
header .search > input {
  width: 100%;
}
section .date {
  position: relative;
}
section .date:after {
  color: #0080bc;
  content: 'c';
  font-family: 'Iconfont' !important;
  font-size: 13px;
  line-height: 13px;
  position: absolute;
  right: 8px;
  top: 7px;
}
.icon-loading {
  font-family: 'Iconfont';
  text-transform: lowercase;
}
/*-----  End of Fonts  ------*/
/*=============================
=            Animations       =
=============================*/
.icon-loading {
  animation: spinner linear 2s;
  animation-iteration-count: infinite;
  transform-origin: 50% 50%;
  -webkit-animation: spinner linear 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-transform-origin: 50% 50%;
  -moz-animation: spinner linear 2s;
  -moz-animation-iteration-count: infinite;
  -moz-transform-origin: 50% 50%;
  -o-animation: spinner linear 2s;
  -o-animation-iteration-count: infinite;
  -o-transform-origin: 50% 50%;
  -ms-animation: spinner linear 2s;
  -ms-animation-iteration-count: infinite;
  -ms-transform-origin: 50% 50%;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -o-transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(360deg);
  }
}
@-ms-keyframes spinner {
  0% {
    -ms-transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
  }
}
/*-----  End of Animations  ------*/
/*======================================
=            General Styles            =
======================================*/
* {
  border: none;
  margin: 0;
  padding: 0;
}
html,
body {
  color: #424242;
  font-family: 'DIN Pro', Arial, sans-serif;
  font-size: 15px;
  line-height: 24px;
}
h1,
h2,
h3,
.h3,
h4,
h5,
h6 {
  font-family: 'DIN Pro', Arial, sans-serif;
  font-weight: 500;
}
h1 {
  margin: 0 auto;
}
h1 span {
  clip: rect(0px, 0px, 0px, 0px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
h2,
.h2 {
  color: #454661;
  font-size: 23px;
  line-height: 28px;
  margin: 0 0 15px 0;
  padding: 0;
}
:is(h2, .h2):not(.h3, .h4, h2.headline-case-default) {
  text-transform: uppercase;
}
:is(h2, .h2).text-normalize {
  text-transform: none;
  font-size: 22px;
}
h3,
.h3,
legend {
  color: #454661;
  font-size: 18px;
  line-height: 23px;
  font-weight: 500;
  margin: 0 0 6px 0;
  padding: 0;
}
h4,
.h4 {
  color: #4E4E4E;
  font-size: 14px;
  line-height: 19px;
  margin: 0 0 6px 0;
  padding: 1px 0 0 0;
}
:is(h2, .h2):not(.h3, .h4) + :is(h3, .h3, h4, .h4) {
  margin-top: -10px;
}
:is(h4, .h4) + :is(h3, .h3) {
  padding-top: 1px;
}
a {
  color: #0080bc;
  font-weight: 500;
  text-decoration: none;
  transition: color 0.2s;
}
a:hover {
  color: #66b3d7;
}
strong,
b {
  font-weight: 500;
}
.wrapper {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1280px;
  min-width: 993px;
  padding: 0 26px;
  position: relative;
  width: 100%;
}
.marginal-header {
  /* 		margin-bottom: 25px; */
}
.marginal-header h2,
.marginal-header h3,
.marginal-header h4,
.marginal-header h5 {
  background-color: #7fabd0;
  color: #fff;
  font-size: 16px;
  margin-bottom: 0;
  line-height: 28px;
  padding: 15px 26px 14px;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-justify {
  text-align: justify;
}
/*-----  End of General Styles  ------*/
.alert-box {
  padding: 12px 20px;
  background-color: transparent;
  border: 1px solid transparent;
  -webkit-border-radius: 4px 4px 4px 4px;
  -moz-border-radius: 4px 4px 4px 4px;
  border-radius: 4px 4px 4px 4px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.alert-box:first-child {
  margin-top: 0;
}
.alert-box.orange {
  background-color: #fff4db;
  border-color: #FFCD74;
  color: #e67905;
}
.alert-box.grey {
  background-color: #808080;
  border-color: #808080;
  color: #ffffff;
}
.alert-box.blue {
  background-color: #0080bc;
  border-color: #0080bc;
  color: #ffffff;
}
.alert-box.red {
  background-color: #c9282d;
  border-color: #c9282d;
  color: #ffffff;
}
.alert-box.green,
.alert.alert-success .alert-message {
  background-color: #DEF0D8;
  border-color: #D7E4D9;
  color: #47735C;
}
.alert-box.error {
  background-color: #f6d7d7;
  border-color: #d80d0d;
  color: #d80d0d;
}
.alert-box span {
  display: block;
  font-size: 15px;
  line-height: 24px;
}
.alert-box span.smaller {
  font-size: 13px;
}
.alert-box.green-text,
.alert-box.blue-text {
  font-weight: bold;
  padding: 0;
}
.alert-box.green-text {
  color: #2d8b1c;
}
.alert-box.blue-text {
  color: #3168A3;
}
/*=====================================
=            Content Styles            =
======================================*/
.wrapper > a:first-child {
  margin-top: 26px;
}
section {
  padding-top: 26px;
  padding-bottom: 7px;
}
section:not(.menu) {
  height: 100%;
}
/* section:last-of-type {padding-bottom:0;} */
section.seamless {
  padding-bottom: 0;
}
section.blue {
  padding-bottom: 26px;
}
section.white {
  padding-bottom: 26px;
}
section.gray {
  padding-bottom: 26px;
}
section.menu {
  padding-top: 0;
}
section.errorpage .wrapper {
  height: 500px;
  position: relative;
}
section.errorpage .wrapper > div {
  z-index: 2;
}
section.errorpage .wrapper > span {
  color: #f0f0f3;
  font-family: 'DIN Pro';
  font-size: 493px;
  font-weight: 500;
  line-height: 493px;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
}
section p {
  margin-bottom: 13px;
}
section > .wrapper ul:not(.progress, .year-navigation, .reset-list),
section > .wrapper ol:not(.reset-list) {
  margin: 0 0 26px 0;
  padding: 0 0 0 20px;
}
section > .wrapper ul li,
section > .wrapper ol li {
  /*color: #0080bc;*/
}
section > iframe {
  height: 580px;
  margin-top: 7px;
  width: 100%;
}
h2 + .back {
  margin-bottom: 26px;
}
.back + .box {
  margin-top: 26px;
}
div.ce-gallery.ce-border img {
  border: 1px solid #c1d7e9;
  box-sizing: border-box;
}
div.blog div.ce-gallery.ce-border img {
  border: 1px solid #c1d7e9;
  box-sizing: content-box;
}
div.ce-last {
  margin-bottom: 19px !important;
}
section:not(.blue) > .wrapper > .content-slider {
  margin-bottom: 19px;
}
.google-map {
  display: none;
}
.google-map + section.blue {
  margin-top: -6px;
}
.ce-gallery .ce-row {
  margin: -6.5px;
}
.ce-gallery .ce-column {
  padding: 6.5px;
  margin: 0;
}
.ce-intext.ce-left .ce-gallery {
  margin-right: 52px;
}
.ce-intext.ce-right .ce-gallery {
  margin-left: 52px;
}
.ce-intext:not(.ce-nowrap) .ce-gallery {
  margin-bottom: 26px;
}
.ce-intext.ce-nowrap .ce-gallery {
  margin-bottom: 0;
}
.ce-textpic:not(:last-child) {
  margin-bottom: 13px;
}
.ce-bodytext p:last-child,
.ce-bodytext ol:last-child,
.ce-bodytext ul:last-child {
  margin-bottom: 0;
}
.ce-bodytext h1 .link,
.ce-bodytext h2 .link,
.ce-bodytext h3 .link,
.ce-bodytext h4 .link,
.ce-bodytext h5 .link {
  font-size: inherit;
  max-width: 100%;
}
.ce-bodytext .link {
  display: inline;
}
/*======  Breadcrumb  ======*/
.breadcrumb {
  color: #7FABCF;
  font-size: 14px;
  list-style: none;
  margin: 2px 0 9px 0 !important;
  padding-left: 0 !important;
}
.breadcrumb a {
  color: #7FABCF;
  font-weight: 400;
}
.breadcrumb li {
  display: inline-block;
}
.breadcrumb li:before {
  content: '>';
  font-size: 14px;
  margin: 0 5px;
}
.breadcrumb li:first-child:before {
  content: '';
  margin: 0;
}
.breadcrumb li:last-child:before {
  font-weight: 400;
}
.breadcrumb li.active {
  font-weight: 500;
}
section form > fieldset > legend {
  display: none;
}
section fieldset > div legend {
  display: inline-block;
}
section fieldset > div {
  margin-bottom: 10px;
}
section form textarea {
  display: inline-block;
}
input[type="submit"][disabled] {
  cursor: pointer;
  opacity: 0.5;
  pointer-events: none;
}
/* text fields */
input[type=email],
input[type=text],
input[type=password],
textarea {
  webkit-transition: border-color 0.4s;
  -o-transition: border-color 0.4s;
  transition: border-color 0.4s;
}
input[type=email]:hover,
input[type=text]:hover,
input[type=password]:hover,
textarea:hover {
  border-color: #0080bc;
  outline: none;
}
input.error[type="email"],
input.error[type="text"],
input.error[type="password"],
input.error[type="tel"],
textarea.error,
input.parsley-error[type="text"],
input.parsley-error[type="email"],
input.parsley-error[type="password"],
input.parsley-error[type="tel"],
textarea.parsley-error {
  border-color: #d43838;
  outline: none;
}
/* selectbox */
.selectbox select::-ms-expand {
  display: none;
}
section label.selectbox {
  border: #DBDADA 1px solid;
  border-radius: 2px;
  display: inline-block;
  height: 100%;
  position: relative;
  -webkit-transition: border-color 0.4s;
  -o-transition: border-color 0.4s;
  transition: border-color 0.4s;
}
section label.selectbox:hover,
section label.selectbox:focus {
  border-color: #0080bc;
  outline: none;
}
section label.selectbox > select:hover,
section label.selectbox > select:focus {
  outline: none;
}
section label.selectbox > *:not(form),
section label.selectbox > form > select {
  padding: 7px 7px 5px;
  min-height: 32px;
}
section label.selectbox > *:not(form),
section label.selectbox > form > select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  border: none;
  color: #B4B4B4;
  padding-right: 20px;
  vertical-align: baseline;
}
section label.selectbox option {
  padding: 0 10px;
}
section label.selectbox > select::-ms-expand {
  display: none;
}
/* target only firefox; the padding inside of select tags is always different than in other browsers*/
@-moz-document url-prefix() {
  section label.selectbox > select {
    padding-left: 3px;
  }
}
section label.selectbox:before {
  background-color: #FFF;
  border-radius: 2px;
  content: '';
  height: 100%;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  width: 15%;
}
section label.selectbox:disabled:before {
  background-color: #ebebe4;
}
section label.selectbox:after {
  content: '=';
  font-family: 'Iconfont';
  font-size: 9px;
  pointer-events: none;
  position: absolute;
  right: 5px;
  top: 10%;
}
/* checkbox & radiobutton */
input[type="radio"],
input[type="checkbox"].custom {
  clip: rect(1px, 1px, 1px, 1px);
  clip: rect(1px 1px 1px 1px);
  border-width: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
  /* radiobutton specific */
  /* checkbox specific */
}
input[type="radio"] + label,
input[type="checkbox"].custom + label {
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  line-height: 24px;
  padding-left: 24px;
  position: relative;
  vertical-align: top;
}
input[type="radio"] + label + input[type="radio"] + label,
input[type="checkbox"].custom + label + input[type="radio"] + label,
input[type="radio"] + label + input[type="checkbox"] + label,
input[type="checkbox"].custom + label + input[type="checkbox"] + label {
  margin-left: 10px;
}
input[type="radio"] + label:before,
input[type="checkbox"].custom + label:before,
input[type="radio"] + label:after,
input[type="checkbox"].custom + label:after {
  border-radius: 50%;
  box-sizing: border-box;
  content: '';
  display: block;
  position: absolute;
}
input[type="radio"] + label:before,
input[type="checkbox"].custom + label:before {
  background-color: #fff;
  border: #0080bc 1px solid;
  height: 17px;
  left: 2px;
  margin-right: 5px;
  top: 3.5px;
  vertical-align: text-top;
  width: 17px;
}
.error input[type="radio"] + label:before,
.error input[type="checkbox"].custom + label:before {
  border-color: #d43838;
}
input[type="radio"] + label:after,
input[type="checkbox"].custom + label:after {
  opacity: 0;
  transition: opacity 0.3s;
}
input[type="radio"] + label:hover:after,
input[type="checkbox"].custom + label:hover:after {
  opacity: 0.3;
}
input[type="radio"]:focus + label:before,
input[type="checkbox"].custom:focus + label:before {
  box-shadow: 0 0 0 2px rgba(0, 128, 188, 0.5);
  /* white focus outline on blue backgrounds */
}
.darkblue input[type="radio"]:focus + label:before,
.darkblue input[type="checkbox"].custom:focus + label:before,
.midblue input[type="radio"]:focus + label:before,
.midblue input[type="checkbox"].custom:focus + label:before {
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);
}
input[type="radio"]:focus + label:after,
input[type="checkbox"].custom:focus + label:after {
  opacity: 0.3;
}
input[type="radio"]:checked + label:after,
input[type="checkbox"].custom:checked + label:after {
  opacity: 1;
}
input[type="radio"][disabled] + label,
input[type="checkbox"].custom[disabled] + label {
  opacity: 0.5;
}
input[type="radio"][type="radio"] + label:before,
input[type="checkbox"].custom[type="radio"] + label:before,
input[type="radio"][type="radio"] + label:after,
input[type="checkbox"].custom[type="radio"] + label:after {
  border-radius: 50%;
}
input[type="radio"][type="radio"] + label:after,
input[type="checkbox"].custom[type="radio"] + label:after {
  background-color: #0080bc;
  height: 9px;
  left: 6px;
  top: 7.5px;
  width: 9px;
}
.error input[type="radio"][type="radio"] + label:after,
.error input[type="checkbox"].custom[type="radio"] + label:after {
  background-color: #d43838;
}
input[type="radio"][type="checkbox"] + label:before,
input[type="checkbox"].custom[type="checkbox"] + label:before {
  border-radius: 3px;
}
input[type="radio"][type="checkbox"] + label:after,
input[type="checkbox"].custom[type="checkbox"] + label:after {
  background: url('../Image/checked.png') no-repeat 0 0 / contain;
  height: 10px;
  left: 4px;
  top: 7px;
  width: 13px;
}
/*==========  Components  =========*/
/* colors */
.blue {
  background-color: #ECF5FD;
}
.gray {
  background-color: #f4f4f4;
}
.darkblue {
  background-color: #1968A8;
}
.midblue {
  background-color: #0080bc;
}
.lightblue {
  background-color: #62B8E8;
}
.red {
  background-color: #c9282d !important;
}
.green {
  background-color: #bac013;
}
.green h2,
.green h3,
.green h4,
.green p,
.green ul,
.green ol {
  color: #fff;
}
.darkblue a,
.darkblue h2,
.darkblue h3,
.darkblue h4,
.darkblue p,
.darkblue ul,
.darkblue ol {
  color: #fff;
}
.midblue a,
.midblue h2,
.midblue h3,
.midblue h4,
.midblue p,
.midblue ul,
.midblue ol {
  color: #fff;
}
hr {
  border-bottom: #B7D2E8 solid 2px;
  margin: 26px 0;
}
p.error {
  color: #AC1611;
  font-weight: 500;
}
p.error:before {
  margin-right: 10px;
}
.clear {
  clear: both;
}
.border-left {
  border-left: 2px solid #C1D7E9;
}
mark,
.csc-sword {
  background-color: #c4c936;
  color: #fff;
}
.note {
  background-color: #f9f9f9;
  margin-bottom: 26px;
  padding: 30px 15px;
  text-align: center;
  color: #4d4d4d;
}
/*========  Publikationen  =======*/
/* styles for page */
.box {
  margin-bottom: 26px;
  position: relative;
}
.box.list {
  padding: 26px;
}
.box.detail {
  padding-bottom: 56px;
  margin-bottom: 0;
}
.box .ce-textpic.ce-intext.ce-left .image {
  width: 140px;
}
.box .ce-textpic.ce-intext.ce-left .image img {
  max-width: 140px;
  height: auto;
}
.tx-dreipc-publications-manager .ce-textpic.ce-intext.ce-left.ce-nowrap .image {
  width: 140px;
}
.tx-dreipc-publications-manager .ce-textpic.ce-intext.ce-left.ce-nowrap .image img {
  max-width: 140px;
  height: auto;
}
.tx-dreipc-publications-manager .ce-textpic.ce-intext.ce-left.ce-nowrap .ce-bodytext {
  margin-left: 166px;
}
.box .buybook {
  left: 320px;
}
.box.detail .buybook {
  left: auto;
}
.box.detail-magazine {
  padding-bottom: 0;
}
.box.form {
  padding: 26px 100px 56px 26px;
}
.box.form .buybook {
  margin: 26px 0;
}
.js-formline {
  display: none;
}
/* styles for magazine */
.magazines h2 {
  border-top: #B7D2E8 solid 2px;
  border-bottom: #B7D2E8 solid 2px;
  padding: 10px 0;
  margin-top: 26px;
}
.magazines .button.back {
  margin-bottom: 26px;
}
.magazines > .button.buybook {
  margin-top: 26px;
}
.magazines .box.detail {
  padding-bottom: 26px;
}
.magazines .box.detail .button.buybook {
  left: 450px;
}
.magazines .box.detail .ce-textpic {
  position: relative;
}
.magazines .section > div {
  border-bottom: 2px solid #b7d2e8;
  margin-bottom: 25px;
  padding-bottom: 7px;
}
.magazines .section > div:last-child {
  border-bottom: 0;
}
.magazines .section > h2 {
  margin-top: 0;
  margin-bottom: 24px;
}
.magazines .section > div h4 {
  color: #777;
  font-size: 18px;
}
.magazines .section > div a.pdf {
  background-position: 0 6px;
  padding-top: 6px;
}
.magazine-details .box .ce-textpic.ce-intext.ce-left .image img {
  max-width: 200px;
}
.magazine-details .box .ce-textpic.ce-intext.ce-left .image {
  width: 200px;
}
.magazine-downloads {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 15px;
}
.magazine-teaser + .magazine-teaser {
  margin-top: 25px;
}
/* styles for startpage box*/
.blue .publications > div:first-child > div {
  background-color: #FFF;
  padding: 26px 26px 7px 26px;
  position: relative;
}
.publications > div:first-child > div > a.right {
  bottom: 26px;
  position: absolute;
  right: 26px;
  top: auto;
  z-index: 1;
}
.publications > div:last-child > .button {
  display: inline-block;
  margin-top: 26px;
}
.publications > div:last-child > .button + .button {
  margin-left: 26px;
}
.publications > div:last-child {
  padding-top: 26px;
}
/* styles for year navigation */
.year-navigation {
  font-size: 0;
  list-style-type: none;
  margin-top: 26px;
}
.year-navigation li {
  display: inline-block;
  margin: 0 26px 26px 0;
}
.year-navigation a {
  background-color: #0080bc;
  color: #fff;
  display: block;
  font-size: 18px;
  font-weight: 500;
  padding: 5px 10px;
  border-radius: 2px;
  -webkit-transition: background-color 0.4s;
  -o-transition: background-color 0.4s;
  transition: background-color 0.4s;
}
.year-navigation a:hover {
  background-color: #83B0D4;
}
/* styles for info content */
.box.list.archive {
  padding: 26px;
}
.box.archive a.button,
.box.archive .buybook {
  position: static;
  bottom: auto;
  left: auto;
}
a.toggle-info:before {
  content: "=";
  display: inline-block;
}
a.toggle-info.on:before {
  -webkit-transform: rotateZ(180deg);
  -moz-transform: rotateZ(180deg);
  -ms-transform: rotateZ(180deg);
  -o-transform: rotateZ(180deg);
  transform: rotateZ(180deg);
}
.archive-info {
  margin: 26px 0 0 166px;
}
/*===========  Grid  ===========*/
.container {
  margin: 0 -26px;
}
/*
	.container.color-right {
		margin-right: 0;
	}

	.container.color-left {
		margin-left: 0;
	}
	*/
.row {
  font-size: 0;
  margin: 0;
}
.row > div {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  font-size: 15px;
  padding: 0 26px;
  vertical-align: top;
  word-wrap: break-word;
}
.row > div:first-child img {
  max-width: 100%;
  height: auto;
}
/* .row > div.blue {padding-bottom:26px;} */
.row > div > .teasercolor-1:not(.teaser),
.row > div > .teasercolor-2:not(.teaser),
.row > div > .teasercolor-3:not(.teaser),
.row > div > .teasercolor-4:not(.teaser),
.row > div > .teasercolor-5:not(.teaser),
.row > div > .teasercolor-6:not(.teaser),
.row > div > .teasercolor-7:not(.teaser),
.row > div > .teasercolor-8:not(.teaser),
.row > div > .blue:not(.teaser),
.row > div > .green:not(.teaser) {
  padding: 26px;
}
.row > div > .white {
  padding-top: 26px;
}
.row.hasColor > .green,
.row.hasColor > .blue {
  margin-bottom: 26px;
  /*padding-top:26px;*/
}
.row.hasColor + h2,
.row.hasColor + h4 {
  margin-top: 19px;
}
.row.hasColor + h3 {
  margin-top: 20px;
}
.row-40-40-20.hasColor,
.row-33-33-33.hasColor {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.row-40-40-20.hasColor > div.white:first-child,
.row-33-33-33.hasColor > div.white:first-child {
  padding-left: 0;
}
.row-40-40-20.hasColor > div.white:last-child,
.row-33-33-33.hasColor > div.white:last-child {
  padding-right: 0;
}
/* 	.row.hasColor:not(.publications) > div {padding-top:26px;} */
.row.columns-33.hasColor:not(.publications) > div,
.row-40-40-20.hasColor > div {
  padding-bottom: 26px;
  padding-top: 26px;
}
/* HEADLINES
	body#home section > .wrapper > .row:not(.hasColor) > div h2 {border-bottom:2px solid #fff; margin-bottom:0; padding-bottom:13px;}
	body#home section > .wrapper > .row:not(.hasColor) > div:first-of-type h2 {border-color:#b7d2e8;}
	*/
#home .container > .row:not(.publications) > div > h2:first-child,
#home .container.color-right > .row:not(.publications) > div > div > h2:first-child,
#home .container.color-left > .row:not(.publications) > div > div > h2:first-child {
  background: #ffffff;
  border-bottom: 2px solid #b7d2e8;
  margin: -26px 0 26px;
  padding: 0 0 13px;
}
#home .container > .row:not(.publications) > div > h2:first-child {
  margin-top: 0;
}
#home .container.color-right > .row:not(.publications) > div:last-child > .blue > h2:first-child,
#home .container.color-left > .row:not(.publications) > div:first-child > .blue > h2:first-child,
#home .container.color-right > .row:not(.publications) > div:last-child > .green > h2:first-child,
#home .container.color-left > .row:not(.publications) > div:first-child > .green > h2:first-child {
  border-bottom-color: transparent;
  margin-left: -26px;
  margin-right: -26px;
  padding-left: 26px;
  padding-right: 26px;
}
#home .container.color-right > .row:not(.publications) > div:last-child > .green > h2:first-child,
#home .container.color-left > .row:not(.publications) > div:first-child > .green > h2:first-child {
  color: #454661;
}
/*
	.row .row > div {padding-left:0; padding-right:0;}
	.wrapper > .row > div .row > div {padding: 26px 0;}
	.wrapper > .row > div .row > div:last-child {padding-left: 26px;}
	*/
/* column divs would be pushed out of the grid */
/* therefore remove padding-left if div has no blue background */
/*
	.wrapper > .row > div:first-of-type:not(.blue):not(.green) {padding-left: 0;}
	.wrapper > .row .row > div:first-of-type:not(.blue):not(.green) {padding-left: 0;}
	*/
.news .list-view .container {
  margin: 0;
}
.news .list-view.layout-blog .news-date {
  float: left;
  margin: 0;
}
.news .list-view.layout-blog p:last-child {
  margin-bottom: 0;
}
.news .list-view.layout-blog + .more {
  display: inline-block;
  margin-bottom: 20px;
}
.news .list-view .row > div.image,
.news.seminar .list-view .row > div {
  padding-left: 0;
}
/*
	.container.hasColor > .row > div:first-of-type:not(.blue):not(.green) {
		padding-left: 0;
	}
	*/
.col-10 {
  width: 10%;
}
.col-15 {
  width: 15%;
}
.col-20 {
  width: 20%;
}
.col-25 {
  width: 25%;
}
.col-30 {
  width: 30%;
}
.col-35 {
  width: 35%;
}
.col-35 .col-40 {
  width: 100%;
  padding: 0;
}
.col-40 {
  width: 40%;
}
.col-45 {
  width: 45%;
}
.col-50 {
  width: 50%;
}
.col-50.green,
.col-50.blue {
  padding-bottom: 13px;
}
.col-55 {
  width: 55%;
}
.col-60 {
  width: 60%;
}
.col-65 {
  width: 65%;
}
.col-70 {
  width: 70%;
}
.col-75 {
  width: 75%;
}
.col-80 {
  width: 80%;
}
.col-85 {
  width: 85%;
}
.col-90 {
  width: 90%;
}
.row.columns-25,
.row.columns-33,
.row.columns-50 {
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  flex-direction: row;
}
.row.columns-25 > .col,
.row.columns-33 > .col,
.row.columns-50 > .col {
  display: inline-block;
}
.row.columns-25 > .col.media img,
.row.columns-33 > .col.media img,
.row.columns-50 > .col.media img {
  margin-top: 0;
}
.row.columns-25 > .col {
  width: 25%;
}
.row.columns-25 > .col.media > div:first-child {
  float: none;
  margin-bottom: 10px;
}
.row.columns-33 > .col {
  width: 33.3%;
}
.row.columns-50 > .col {
  width: 50%;
}
.row.columns-50.contact {
  display: block;
}
.row.columns-50.contact > .col.media {
  padding: 0 26px;
}
.row.columns-50.contact > .col.media:nth-child(even) {
  padding-left: 0;
}
.row.columns-50.contact > .col.media:nth-child(odd) {
  padding: 0 26px;
}
.row.columns-50.contact > .col.media:nth-child(2n+1) {
  padding-left: 0;
}
.row > div > .selectbox:first-child {
  display: inline-block;
  margin-bottom: 26px;
}
/* columns-33-margin layout */
.row.columns-33-margin.hasColor:not(.publications) {
  margin: 0 -10px;
}
.row.columns-33-margin.hasColor:not(.publications) > div {
  margin: 0 10px;
  padding: 0;
}
.row.columns-33-margin.hasColor:not(.publications) > div.green,
.row.columns-33-margin.hasColor:not(.publications) > div.blue,
.row.columns-33-margin.hasColor:not(.publications) > div.gray-border {
  padding: 26px;
}
.row.columns-33-margin.hasColor:not(.publications) > div.gray-border {
  border: 5px solid #f4f4f4;
}
.row.columns-33-margin.hasColor:not(.publications) > div div.ce-textpic .image:first-child {
  padding-top: 0;
}
.row.columns-33-margin.hasColor:not(.publications) > div .button {
  margin-right: 20px;
}
.row.columns-33-margin.hasColor:not(.publications) > div .button:last-child {
  margin-right: 0;
}
.row.columns-33-margin.hasColor:not(.publications) input[type="submit"] {
  padding-bottom: 6px;
  padding-top: 7px;
}
.media {
  overflow: hidden;
  margin-bottom: 26px;
}
.teaser .content .col:last-child .address.media,
.teaser .content .col-40:last-child .address.media {
  margin-bottom: 0px;
}
.no-media {
  margin-bottom: 26px;
}
.media > div:first-child {
  float: left;
  margin-right: 26px;
}
.media > div:first-child img {
  margin-top: 7px;
}
.media > div:last-child {
  overflow: hidden;
}
.media > div:last-child h3 {
  margin-bottom: 0;
}
.border-left > h3:first-child {
  margin: 0;
}
a.search-pdf-icon::after {
  background: url('../Icon/pdf.png') no-repeat;
  background-position-x: right;
  content: '';
  display: inline-block;
  height: 21px;
  left: 2px;
  position: relative;
  top: 3px;
  width: 21px;
}
a.search-pdf-icon::after {
  background: url('../Icon/pdf.png') no-repeat;
  background-position-x: right;
  content: '';
  display: inline-block;
  height: 21px;
  left: 2px;
  position: relative;
  top: 3px;
  width: 21px;
}
a.more:before {
  color: #0080bc;
}
ul.downloads {
  list-style: none;
  padding-left: 0 !important;
}
ul.downloads a.pdf {
  padding-top: 0;
  padding-bottom: 0;
}
ul.downloads li {
  padding-bottom: 12px;
}
ul.downloads li > p {
  margin: 0 0 0 25px;
}
ul.downloadliste {
  list-style: none;
  padding-left: 0 !important;
}
ul.downloadliste a.pdf {
  padding-top: 0;
  padding-bottom: 0;
}
ul.downloadliste li {
  padding-bottom: 12px;
  background: url(../Icon/pdf.png) no-repeat 0 2px;
  display: block;
  padding: 2px 0 4px 25px;
}
ul.downloadliste li > p {
  margin: 0 0 0 25px;
}
/*
section > .wrapper > h2 {margin-top:-7px;}
section > .wrapper > .row > div > h2:first-of-type {margin-top:-7px;}
section > .wrapper .content-slider .owl-item h2:first-of-type {margin-top:-7px;}
*/
@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop,
  section > .wrapper > h2 {
    margin-top: 0;
  }
  /* IE11 */
  *::-ms-backdrop,
  section > .wrapper > .row > div > h2:first-of-type {
    margin-top: 0;
  }
  /* IE11 */
  *::-ms-backdrop,
  section > .wrapper .content-slider .owl-item h2:first-of-type {
    margin-top: 0;
  }
  /* IE11 */
}
/*-----  End of Content Styles  ------*/
/*===============================
=            Modules            =
===============================*/
/*=======  Event-Search  =======*/
.event_search {
  font-size: 0;
  height: 100%;
  position: relative;
}
.event_search > .col-55 {
  font-size: 0;
  padding-right: 0;
  padding-left: 0;
  width: 60%;
}
.event_search .col-55 > div {
  font-size: 15px;
  letter-spacing: 0px;
}
.event_search .col-45 {
  padding-left: 0;
  width: 40%;
}
.event_search a {
  font-weight: 500;
}
.event_search a:after {
  font-family: 'Iconfont';
  content: 'i';
  margin-left: 5px;
  vertical-align: middle;
}
.event_search h3 {
  margin-bottom: 15px;
}
.event_search input {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}
.event_search .keyword label,
.event_search .dates label {
  display: inline-block;
  margin-bottom: 10px;
}
.event_search > div > div {
  display: inline-block;
  font-size: 15px;
  position: relative;
  vertical-align: top;
}
.event_search > div > div > h3 + a {
  position: absolute;
  right: 26px;
  top: 3px;
}
.event_search .topics label {
  margin-bottom: 19px;
  width: calc(50% - 2px);
  margin-left: 0 !important;
  padding-right: 22px;
}
.event_search .location {
  height: 100%;
  width: 100%;
}
.event_search .location > div {
  border-left: #C1D7E9 solid 2px;
  height: 100%;
  padding-left: 26px;
}
.event_search .location > h3 {
  margin-left: 26px;
}
.event_search .location > div {
  position: relative;
}
.event_search .location > div .result-count {
  background: url('../Image/resultCount.png') no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  color: #FFF;
  line-height: 18px;
  padding-bottom: 8px;
  pointer-events: none;
  position: absolute;
  text-align: center;
  width: 30px;
}
.event_search .location > div > label {
  display: block;
}
.event_search .location > div label:last-child {
  display: none;
}
.event_search .location > div svg {
  display: block;
  margin-left: 20%;
}
.event_search .keyword {
  width: 42%;
  position: relative;
}
.event_search .keyword label,
.event_search .keyword label input {
  width: 100%;
}
.event_search .keyword label input {
  padding-right: 25px;
}
.event_search .dates {
  font-size: 0;
  margin-left: 10%;
  width: 45%;
  position: relative;
}
.event_search .dates > a,
.event_search .keyword > a {
  position: absolute;
  right: 0;
  top: 0;
}
.event_search .dates > a {
  right: 4px;
}
.event_search .dates label {
  margin-bottom: 10px;
  width: 44%;
}
.event_search .dates label input {
  width: 100%;
}
.event_search .dates label:last-of-type {
  margin-left: 10%;
}
.event_search .controls {
  display: block;
  margin-bottom: 26px;
  margin-top: 48px;
  padding: 0;
}
.event_search > .controls {
  width: 60%;
}
.event_search .controls button {
  line-height: 24px;
  margin-right: 26px;
}
.event_search .controls .button.reset:before {
  content: '';
}
.event_search .controls .button.reset {
  color: #0080bc;
  background-color: #c5daeb;
}
.event_search .controls .button.reset:hover {
  background-color: #83b0d4;
}
.tooltip {
  background-color: #0080bc;
  border-radius: 5px;
  color: #FFF;
  display: none;
  font-size: 14px;
  padding: 1px 10px;
  position: absolute;
  z-index: 1;
}
.tx-solr-search-no-results {
  margin-bottom: 26px;
}
@media (max-width: 1240px) {
  .event_search .dates > a,
  .event_search .keyword > a {
    top: 80px;
  }
}
/*=====  QuickSearch  =====*/
.owl-carousel + .tx-solr {
  margin-top: 26px;
  margin-bottom: 13px;
  padding-top: 26px;
  border-top: 2px solid #b7d2e8;
}
.quick-search > label {
  display: block;
  margin-bottom: 26px;
  width: 50%;
}
.quick-search > label > input {
  padding-right: 0;
  width: 100%;
}
.quick-search > label > * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
}
.quick-search > button {
  padding-left: 26px;
  display: inline-block;
}
.quick-search > button + a {
  display: inline-block;
  margin-left: 26px;
}
/*=====  Newsletter  =====*/
.newsletter {
  font-size: 15px;
  margin-bottom: 26px;
}
.newsletter a:after {
  content: none;
}
/*.newsletter .row a:after, .newsletter .reset:after {content: 'i';}*/
.newsletter .row > .col-55 {
  padding-left: 0;
  width: 60%;
}
.newsletter .row > .col-45 {
  padding-right: 0;
}
.newsletter .date:after {
  top: -2px;
}
.newsletter .controls {
  margin-bottom: 18px;
  margin-top: 26px;
}
.newsletter .row > div > div {
  display: inline-block;
  font-size: 15px;
  position: relative;
  vertical-align: top;
}
.newsletter .row > div > div > h3 + a {
  position: absolute;
  right: 3px;
  top: 0;
}
.newsletter .note {
  margin-top: 26px;
}
.newsletter div.send {
  display: inline-block;
  padding-right: 0;
  text-align: right;
  vertical-align: top;
  width: 50%;
}
.newsletter div.send button {
  line-height: 24px;
}
.newsletter div.link {
  display: inline-block;
  padding-left: 0;
  vertical-align: top;
  width: 50%;
}
a.button.back.newsletter {
  margin-bottom: 26px;
}
.newsletter > label {
  margin-right: 26px;
}
.newsletter > .edit-subscription-details > label {
  margin-right: 26px;
}
.newsletter > label + label + p {
  color: #aaa;
  font-size: 13px;
  margin-top: 10px;
}
form.newsletter input {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}
.newsletter div.topics > a:after,
.newsletter div.location > a:after,
.newsletter div.controls > a.button.reset:after {
  font-family: 'Iconfont';
  content: 'i';
  margin-left: 5px;
}
.newsletter div.location label.all {
  margin-left: 0 !important;
}
.newsletter .controls {
  margin: 0;
}
.newsletter .controls > input {
  line-height: 22px;
  margin-right: 26px;
}
/*=======  SiteMap  =======*/
.csc-sitemap ul {
  list-style: none;
}
.csc-sitemap > ul > li {
  display: block;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 10px;
  vertical-align: top;
}
.csc-sitemap > ul > li ul {
  margin-bottom: 0;
}
.csc-sitemap > ul > li > ul > li {
  font-size: 15px;
  font-weight: 400;
}
/*=======  Organigramm  =======*/
.hierarchy {
  background-color: #eaf7ff;
  border-radius: 3px;
  padding: 5px 1.5%;
  margin-bottom: 26px;
}
.hierarchy > h4 {
  margin-top: 10px;
}
.hierarchy > div {
  font-size: 0;
}
.hierarchy > div.blue {
  padding: 5px 0;
  background-color: #a4c6e1;
  border-radius: 3px;
  margin-top: 5px;
}
.hierarchy > div.blue > .part {
  min-height: 465px;
}
.hierarchy .part {
  position: relative;
  min-height: 180px;
  width: 30%;
  display: inline-block;
  background-color: #fff;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 15px;
  margin: 5px 1.5%;
  text-align: left;
  padding: 7px;
  border-radius: 3px;
  vertical-align: top;
}
.hierarchy .part.offset-2 {
  margin-left: 67.5%;
}
.hierarchy.parts-50 .part {
  width: 47%;
  margin: 5px 1.5%;
}
.hierarchy hr {
  border-width: 1px;
}
.hierarchy div.head {
  min-height: initial;
  text-align: center;
  width: 96.5%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0 1.5% 0 1.5%;
}
.hierarchy .arrow-bottom:before {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 15px 0 15px;
  border-color: #ffffff transparent transparent transparent;
  content: '';
  bottom: -15px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.hierarchy .arrow-right:after {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 0 15px 15px;
  border-color: transparent transparent transparent #ffffff;
  bottom: 0;
  top: 0;
  right: -15px;
  margin: auto;
  content: '';
}
.hierarchy .arrow-bottom + div {
  margin-top: 10px;
}
.hierarchy .arrow-left:after {
  position: absolute;
  height: 0;
  border-style: solid;
  border-width: 15px 15px 15px 0;
  border-color: transparent #ffffff transparent transparent;
  content: '';
  bottom: 0;
  top: 0;
  left: -15px;
  margin: auto;
}
.organization {
  font-size: 0;
  min-height: 400px;
  background: #0080bc url('../Image/organigramm.png') no-repeat 50% 0;
  -webkit-background-size: 102% 408px;
  background-size: 102% 408px;
}
.organization .part {
  padding: 7px;
  font-size: 13px;
  display: inline-block;
  vertical-align: top;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.organization .center {
  text-align: center;
  margin-bottom: 105px;
  padding-top: 13px;
}
.organization > .part {
  width: 19%;
  margin-left: 1%;
  line-height: 17.46995px;
}
.organization .center > .part {
  width: 50%;
}
.organization h5 {
  font-size: 15px;
}
.organization h4 {
  color: #454661;
  font-size: 16px;
  font-weight: 500;
}
.organization p {
  margin-bottom: 3px;
}
.organization p,
.organization a {
  font-size: 13px;
}
/*=======  DatePicker  =======*/
.ui-datepicker {
  padding-bottom: 7px;
  background: #29A2D9;
  border: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  box-shadow: 0 1px 3px #252525;
  position: absolute;
  display: none;
  z-index: 9999 !important;
}
.ui-datepicker-header {
  background: transparent;
  border: none;
  border-bottom: 1px solid #2D97CB;
  font-size: 11px;
  font-weight: normal;
  text-transform: uppercase;
}
.ui-datepicker-header .ui-state-hover {
  background: transparent;
  border-color: transparent;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  cursor: pointer;
}
.ui-datepicker .ui-datepicker-title {
  padding: 10px;
  text-align: center;
  font-size: 18px;
  color: #fff;
}
.ui-datepicker .ui-datepicker-next,
.ui-datepicker .ui-datepicker-prev {
  position: absolute;
  top: 10px;
}
.ui-datepicker .ui-datepicker-next {
  right: 10px;
}
.ui-datepicker .ui-datepicker-prev {
  left: 10px;
}
.ui-datepicker .ui-datepicker-next span,
.ui-datepicker .ui-datepicker-prev span {
  font-weight: normal;
  margin-top: 0;
  top: 0;
  font-family: 'Iconfont';
  color: #fff;
  font-size: 18px;
}
.ui-datepicker table {
  margin: 0;
}
.ui-datepicker th {
  border: none;
  border-top: 1px solid #3FABDC;
  color: #9FD9F1;
  font-size: 12px;
  font-weight: normal;
  text-shadow: 0 0 2px #45C4F8;
  text-transform: uppercase;
}
.ui-datepicker td {
  border: none;
  padding: 0;
}
td .ui-state-default {
  background: transparent;
  border: none;
  color: #EFEFEF;
  font-size: 12px;
  font-weight: normal;
  margin: 0;
  padding: 10px;
  text-align: center;
}
td .ui-state-active,
td .ui-state-hover {
  background: #1B7FB0;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
/*=======  Login  =======*/
.user_login,
.user_logout {
  color: #FFF;
  position: absolute;
  right: 316px;
  top: 80px;
}
.user_login > a,
.user_logout input[type='submit'] {
  color: #FFF;
  font-size: 15px;
}
.user_logout input[type='submit'] {
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-weight: 500;
  font-family: 'DIN Pro';
}
.user_login form {
  display: none;
}
.mfp-content > form {
  background-color: #FFF;
  display: table;
  margin: 0 auto;
  padding: 26px;
  position: relative;
}
.mfp-content > form label {
  display: inline-block;
  width: 120px;
}
.mfp-content > form > fieldset input[type='text'] {
  margin-bottom: 10px;
}
.mfp-content > form > fieldset input[type='submit'] {
  margin-top: 16px;
}
.col-35 label {
  width: 100%;
  display: block;
  position: relative;
  height: 40px;
}
.col-35 label input {
  position: absolute;
  right: 0;
  width: 200px;
}
.contentlogin button.submit {
  line-height: 24px;
  vertical-align: top;
}
.contentlogin .form-line.controls div.link {
  display: inline-block;
  padding: 5px 0 0 0;
  vertical-align: top;
  width: 50%;
}
.contentlogin .form-line.controls div.send {
  display: inline-block;
  padding-right: 0;
  text-align: right;
  vertical-align: top;
  width: 50%;
}
.contentlogin#logout-form {
  margin-bottom: 26px;
}
.forgot-password {
  margin-bottom: 15px;
  padding-top: 39px;
}
/*=======  Search  =======*/
section .page-search {
  margin-top: 26px;
  margin-bottom: 24px;
  display: block;
}
section .page-search:after {
  top: 25px;
  font-size: 23px;
  right: 26px;
}
section .page-search > input {
  padding: 10px 60px 10px 26px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 23px;
}
.spelling-suggestions a {
  text-transform: uppercase;
}
form#tx-solr-search-form-publication label.search {
  display: block;
  margin-bottom: 26px;
}
form#tx-solr-search-form-publication input#publicationSearchField {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#tx-solr-search-form-header .input-group .search .search-span-button,
#tx-solr-search-form-pi-results .search .search-span-button {
  position: absolute;
  right: 9px;
  top: -6px;
}
#tx-solr-search-form-header .input-group .search .search-button:after,
#tx-solr-search-form-pi-results .search .search-button:after {
  color: #0080bc;
  content: 's';
  font-family: 'Iconfont' !important;
  font-size: 13px;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}
#tx-solr-search-form-header .input-group .search:after,
#tx-solr-search-form-pi-results .search:after {
  display: none;
}
#tx-solr-search-form-pi-results .search .search-span-button {
  position: absolute;
  right: 0;
  top: 0;
}
#tx-solr-search-form-pi-results .search .search-button:after {
  font-size: 23px;
  top: 13px;
  right: 30px;
  transform: none;
}
/*=======  Send Link  =======*/
.mfp-iframe-scaler iframe {
  background-color: #FFFFFF;
  padding: 20px;
}
.mfp-iframe-holder .mfp-close {
  right: -40px;
}
/*========  Slider  =========*/
/* for no JS */
body .owl-carousel {
  display: block;
}
body .owl-carousel > div:not(.owl-controls) {
  display: none;
}
body .owl-carousel > div:first-child {
  display: block;
}
/* 	body .owl-carousel.owl-loaded > div {display: auto;} */
/* header-slider */
#head-slider img {
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  height: auto;
}
#head-slider {
  margin: 0 auto;
  position: relative;
  width: 100%;
  overflow: hidden;
  max-height: 400px;
  max-width: 2520px;
}
#head-slider.single {
  max-width: 1228px;
  width: 100%;
}
#head-slider .owl-prev,
#head-slider .owl-next {
  background-color: rgba(255, 255, 255, 0.8);
  bottom: 0;
  height: 55px;
  margin: auto;
  position: absolute;
  top: 0;
  -webkit-transition: background-color 0.4s;
  -o-transition: background-color 0.4s;
  transition: background-color 0.4s;
  width: 55px;
}
#head-slider .owl-prev {
  left: 26px;
}
#head-slider .owl-next {
  right: 26px;
}
#head-slider .owl-prev:before,
#head-slider .owl-next:before {
  color: #0080bc;
  display: block;
  font-family: 'Iconfont';
  font-size: 22px;
  margin-top: 16px;
  -webkit-transition: color 0.4s;
  -o-transition: color 0.4s;
  transition: color 0.4s;
}
#head-slider .owl-prev:before {
  content: '<';
  margin-left: 17px;
}
#head-slider .owl-next:before {
  content: '>';
  margin-left: 19px;
}
#head-slider .owl-prev:hover,
#head-slider .owl-next:hover {
  background-color: rgba(0, 88, 159, 0.8);
}
#head-slider .owl-prev:hover:before,
#head-slider .owl-next:hover:before {
  color: #FFF;
}
#head-slider.single .owl-item {
  float: none;
}
#head-slider.slider-landingpage .owl-item {
  float: none;
}
#head-slider.slider-landingpage.owl-loaded .owl-item {
  float: left;
}
#head-slider .owl-item {
  overflow: hidden;
}
#head-slider .owl-item > div {
  -webkit-background-size: cover;
  background-size: cover;
  min-height: 400px;
  position: relative;
}
#head-slider .owl-item > div > img {
  position: absolute;
  top: 50%;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  height: auto;
  transform: translateY(-50%);
}
#head-slider .owl-item > div .wrapper {
  min-height: 400px;
  position: relative;
}
#head-slider .teaser.ce-bodytext h2,
#head-slider .teaser.ce-bodytext h3,
#head-slider .teaser.ce-bodytext h4 {
  line-height: 1.2;
  margin-top: 0;
}
#head-slider .teaser.ce-bodytext h2,
#head-slider .teaser.ce-bodytext h3,
#head-slider .teaser.ce-bodytext h4 {
  word-break: break-word;
}
#head-slider .teaser.ce-bodytext h2 strong,
#head-slider .teaser.ce-bodytext h3 strong,
#head-slider .teaser.ce-bodytext h4 strong {
  font-weight: 900;
}
#head-slider .teaser.ce-bodytext p,
#head-slider .teaser.ce-bodytext ul,
#head-slider .teaser.ce-bodytext ol {
  word-break: break-word;
}
#head-slider .teaser.ce-bodytext h2 + h3 {
  margin-top: 14px;
}
#head-slider .teaser.ce-bodytext h3 + h2 {
  margin-top: 14px;
}
#head-slider .teaser.ce-bodytext h3 + p,
#head-slider .teaser.ce-bodytext h4 + p {
  margin-top: 14px;
}
#head-slider .teaser.ce-bodytext h2 + p {
  margin-top: 20px;
}
#head-slider .teaser.ce-bodytext h2:has(+div a),
#head-slider .teaser.ce-bodytext h3:has(+div a),
#head-slider .teaser.ce-bodytext h4:has(+div a) {
  margin-bottom: 20px;
}
.headline-case-upper {
  text-transform: uppercase;
}
.headline-case-default {
  text-transform: unset;
}
#head-slider .owl-item > div .teaser {
  background-color: rgba(255, 255, 255, 0.8);
  bottom: 30px;
  left: 26px;
  padding: 20px;
  position: absolute;
  max-width: 400px;
}
#head-slider.slider-landingpage .owl-item > div .teaser {
  bottom: unset;
  top: 30px;
  background-color: unset;
  font-size: 23px;
  width: 100%;
}
#head-slider.slider-landingpage .owl-item > div .teaser > h2 {
  font-size: 45px;
  line-height: 45px;
}
#head-slider.slider-landingpage .owl-item > div .teaser > h2:not(.header-layout-2) {
  font-size: initial;
  line-height: normal;
}
#head-slider.slider-landingpage .owl-item > div .text-big-layout .text-no-box > h2 {
  line-height: 55px;
}
#head-slider .owl-item > div .teaser p,
header + #head-slider > div .teaser p {
  margin-bottom: 20px;
}
#head-slider .owl-item > div .teaser h2,
header + #head-slider > div .teaser h2 {
  padding: 0;
}
header + .owl-carousel .owl-item > div .text-big-layout {
  padding-top: 50px;
}
header + .owl-carousel .owl-item > div .text-big-layout .teaser {
  width: calc(100% - 40px);
  position: unset;
}
header + .owl-carousel .owl-item > div .teaser.text-gray-box {
  background-color: rgba(255, 255, 255, 0.8);
}
.owl-item .text-small-layout h2 {
  font-size: 16px;
}
.owl-item .text-small-layout h3 {
  font-size: 18px;
}
.owl-item .text-small-layout p {
  font-size: 15px;
}
#head-slider .teaser ul,
#head-slider .teaser ol {
  padding-left: 15px;
}
#head-slider .teaser ul {
  list-style: none;
}
#head-slider .teaser ul li {
  position: relative;
}
#head-slider .teaser ul li:before {
  background: #424242;
  border-radius: 50%;
  content: '';
  display: block;
  height: 5px;
  left: -14px;
  position: absolute;
  top: 10px;
  width: 5px;
}
@media (max-width: 1465px) {
  #head-slider .owl-item > div .teaser {
    left: 110px;
  }
}
@media (max-width: 767px) {
  #head-slider .owl-item > div .text-big-layout .teaser {
    padding: unset;
    width: unset;
  }
  #head-slider .owl-item > div .teaser.text-gray-box {
    background-color: unset;
  }
  #head-slider .owl-item > div .teaser {
    left: 10px;
    right: 10px;
  }
}
/* content-slider and page-slider*/
/* general */
section .owl-carousel.owl-loaded {
  text-align: center;
}
/* section .owl-carousel .row > div:first-child {padding-left: 0;} */
section .owl-carousel .row .blue {
  padding-left: 0;
}
section .owl-carousel .owl-stage-outer {
  text-align: left;
}
section .owl-carousel .owl-controls {
  display: inline-block;
  margin-top: 10px;
  position: relative;
}
section .owl-carousel .owl-prev,
section .owl-carousel .owl-next {
  color: #0080bc;
  font-family: 'Iconfont';
  font-size: 11px;
  position: absolute;
}
/* content-slider */
section .content-slider {
  margin-bottom: 19px;
}
section .content-slider .owl-dots {
  margin-top: -1px;
}
section .content-slider .owl-dot {
  background-color: #83B0D4;
  border-radius: 3px;
  display: inline-block;
  height: 10px;
  margin-left: 15px;
  width: 10px;
}
section .content-slider .owl-dot:first-child {
  margin-left: 0;
}
section .content-slider .owl-dot.active {
  background-color: #0080bc;
}
section .content-slider .owl-prev {
  left: -20px;
}
section .content-slider .owl-next {
  right: -20px;
}
/* put some space between startpage news slider */
section:nth-child(4) > div > div.row.hasColor > div.col-50.blue > div.owl-carousel.content-slider > div.owl-stage-outer > div > div.owl-item.active > div > h4:nth-child(11) {
  margin-top: 26px;
}
.content-slider .owl-item div.ce-gallery img {
  transform-style: flat;
  -webkit-transform-style: flat;
}
/* page-slider (has numbers instead of dots) */
section .page-slider .owl-prev,
section .page-slider .owl-next {
  top: 2px;
}
section .page-slider .owl-prev {
  left: -5px;
}
section .page-slider .owl-next {
  right: -32px;
}
section .page-slider .owl-dot {
  color: #B8D6F0;
  display: inline-block;
  font-size: 14px;
  margin-left: 26px;
  vertical-align: middle;
}
section .page-slider .owl-dot.active {
  color: #0080bc;
}
section .slider-list .owl-item > div > div {
  border-top: 2px solid #C1D7E9;
  padding: 26px 0;
}
/* added by form4 */
/* body .owl-carousel > div.owl-nav { */
/* 	display: block; */
/* } */
/* body .owl-carousel > div.owl-nav .owl-next, */
/* body .owl-carousel > div.owl-nav .owl-prev { */
/* 	cursor: pointer; */
/* } */
/*======  Accordion  =======*/
.open-accordion {
  cursor: pointer;
  position: relative;
  margin: 0;
}
.open-accordion.more:after {
  content: '';
}
.open-accordion:after {
  color: #0080bc;
  content: '=';
  font-family: 'Iconfont';
  font-size: 12px;
  position: absolute;
  right: 5px;
  top: 2px;
  -webkit-transform: rotateZ(0);
  -moz-transform: rotateZ(0);
  -ms-transform: rotateZ(0);
  -o-transform: rotateZ(0);
  transform: rotateZ(0);
  -webkit-transition: -webkit-transform 0.4s;
  -o-transition: -o-transform 0.4s;
  transition: transform 0.4s;
}
.on .open-accordion:after,
.on.open-accordion:after {
  -webkit-transform: rotateZ(180deg);
  -moz-transform: rotateZ(180deg);
  -ms-transform: rotateZ(180deg);
  -o-transform: rotateZ(180deg);
  transform: rotateZ(180deg);
}
.open-accordion + ul {
  list-style: none;
}
.accordion-wrapper:first-of-type {
  border-top: 2px solid #C1D7E9;
}
.accordion-wrapper {
  border-bottom: 2px solid #C1D7E9;
  padding: 19px 0 20px 0;
}
.accordion-wrapper a.open-accordion {
  display: block;
}
.accordion-wrapper .open-accordion:after {
  top: auto;
}
.accordion-wrapper .accordion {
  margin-top: 6px;
}
.accordion-wrapper .accordion p:last-of-type {
  margin-bottom: 0;
}
h3 + .accordion-wrapper {
  margin-top: 12px;
}
/*======  Info-List  =======*/
.infos dl {
  font-size: 0;
  margin-bottom: 26px;
  overflow: hidden;
}
.tx-solr .list-view .results-entry .infos dl {
  margin-bottom: 13px;
}
.infos dl dt,
.infos dl dd {
  font-size: 15px;
  vertical-align: top;
}
.infos dl dt {
  float: left;
  width: 130px;
}
.results-entry > .article-wrap > .infos dl dt {
  width: 130px;
}
.infos dl dt {
  float: left;
  font-weight: 500;
}
.infos dl dd {
  overflow: hidden;
}
.row > div .infos dt {
  margin-bottom: 10px;
}
.row > div .infos dd {
  margin-bottom: 10px;
}
.deadline {
  color: #7FABCF;
}
/*======  List-View  ======*/
.button + .control-bar {
  margin-top: 20px;
}
.control-bar {
  border-bottom: 2px solid #C1D7E9;
  overflow: hidden;
  margin-bottom: 26px;
  padding-bottom: 26px;
  position: relative;
}
.control-bar .button {
  margin-right: 22px;
}
.control-bar .button:last-child {
  margin-right: 0;
}
.control-bar .selectbox:first-child {
  margin-right: 26px;
}
.control-bar .open-accordion:after {
  content: '';
}
.control-bar form > div {
  display: inline-block;
  margin-top: 26px;
  vertical-align: top;
}
.control-bar .left {
  float: left;
}
.control-bar .right {
  float: right;
  padding-top: 2px;
}
.control-bar .bottom {
  clear: both;
  margin-top: 26px;
  overflow: hidden;
}
.control-bar-bottom {
  border-bottom: 0;
  padding-bottom: 0;
}
/* paginator */
.page-navigation {
  border-bottom: 2px solid #C1D7E9;
  padding: 0 0 7px 0;
  overflow: hidden;
}
.page-navigation.is-below {
  padding-top: 19px;
}
.page-navigation p {
  float: left;
  font-size: 13px;
}
.page-navigation > div ul {
  display: inline-block;
  float: right;
  font-size: 0;
  list-style: none;
  margin: 0;
}
.page-navigation ul > li {
  color: #0080bc;
  display: inline-block;
  font-size: 14px;
  margin-left: 26px;
  vertical-align: middle;
}
.page-navigation ul > li a {
  color: #B8D6F0;
}
.page-navigation .next,
.page-navigation .prev,
.page-navigation .first,
.page-navigation .last {
  font-family: 'Iconfont';
  font-size: 11px;
  line-height: 9px;
  vertical-align: middle;
}
.page-navigation .next a,
.page-navigation .prev a,
.page-navigation .first a,
.page-navigation .last a {
  color: #0080bc;
}
.page-navigation .next {
  margin-left: 26px;
}
section > .wrapper .tx-solr > .page-navigation:last-child ul:not(.progress):not(.year-navigation) {
  margin-bottom: 0;
}
/* list */
.list-view > h2,
.detail-view > h2 {
  padding-bottom: 26px;
  border-bottom: 2px solid #C1D7E9;
}
.detail-view > h2 + h4 {
  margin-top: 0;
}
.list-view > article:first-of-type {
  border-top: none;
}
.list-view > h2:first-child {
  margin-top: 26px;
}
.list-view > article,
.detail-view > article {
  border-top: 2px solid #C1D7E9;
  padding: 26px 0;
  position: relative;
}
.list-view > article:last-child,
.detail-view > article:last-child {
  border-bottom: 2px solid #C1D7E9;
}
.list-view > article .infos > .infos-header,
.detail-view > article .infos > .infos-header {
  display: flex;
  align-items: flex-start;
}
.list-view > article .infos > .infos-header img,
.detail-view > article .infos > .infos-header img {
  margin-right: 20px;
  flex-shrink: 0;
}
.list-view > article .infos .infos-content,
.detail-view > article .infos .infos-content {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 26px;
}
.list-view > article .infos .infos-termine,
.detail-view > article .infos .infos-termine {
  width: calc(33.3333% - 14px);
}
.list-view > article .infos .infos-termine.seminar-interesting-detail-view,
.detail-view > article .infos .infos-termine.seminar-interesting-detail-view {
  width: calc(66.6666% - 14px);
}
.infos-content-column-left {
  flex: 0.65;
}
.infos-content-column-right {
  width: 25%;
  margin-left: auto;
}
.list-view > article .infos-title p,
.detail-view > article .infos-title p {
  text-transform: uppercase;
}
.list-view > article .infos h3 > span,
.detail-view > article .infos h3 > span {
  font-size: 15px;
  line-height: 24px;
  font-weight: 100;
}
.list-view > article .col-65.infos.news-list p {
  text-transform: uppercase;
  color: #7FABCF;
}
.list-view > article .col-65.infos.news-list p.news-teser-abstract {
  text-transform: none;
  color: #424242;
}
.list-view > article .infos.indented {
  max-width: 970px;
  padding-left: 26px;
}
.list-view > article > .article-wrap > .button,
.detail-view > article > .article-wrap > .button {
  display: inline-block;
  margin-right: 22px;
}
/* for seminar search result page*/
.detail-view {
  margin-bottom: 26px;
}
.detail-view.seminar-container {
  margin-top: 26px;
}
.white .wrapper .row.hasColor .col-50.blue.slider-list.seminar-padding {
  padding: 26px;
}
.white .wrapper .row.hasColor .col-50.seminar-padding {
  padding: 26px;
}
.list-view .article-wrap,
.detail-view .article-wrap {
  position: relative;
}
.list-view > article .booking div,
.detail-view > article .booking div {
  font-size: 14px;
}
.list-view > article .booking div span,
.detail-view > article .booking div span {
  color: #454661;
  font-weight: 500;
}
.list-view > article .booking .button-wrapper,
.detail-view > article .booking .button-wrapper {
  margin-top: 15px;
  width: 100%;
  text-align: right;
}
.list-view > article .booking a:focus,
.detail-view > article .booking a:focus,
.list-view > article .booking button:focus,
.detail-view > article .booking button:focus {
  outline: none;
}
.seminar-info-content-column.seminar-info-content-column-right {
  width: 258px;
}
.booking-prices {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 26px;
}
.booking-prices > div {
  width: calc(50% - 13px);
}
.list-view > article .infos-termine-wrapper,
.detail-view > article .infos-termine-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 26px;
}
.list-view > article .infos-termine-wrapper .error,
.detail-view > article .infos-termine-wrapper .error {
  margin-top: 26px;
}
.list-view > article .infos-termine-wrapper div,
.detail-view > article .infos-termine-wrapper div {
  display: inline-block;
  vertical-align: top;
  width: 30%;
}
.list-view > article .infos-termine-wrapper div > ul,
.detail-view > article .infos-termine-wrapper div > ul {
  list-style: none;
  padding-left: 0 !important;
  margin: 0 !important;
}
.list-view > article .infos-termine-wrapper div > ul a,
.detail-view > article .infos-termine-wrapper div > ul a {
  font-weight: 500;
}
.list-view > article .booking .price-hint {
  width: 100%;
  font-size: 12px;
}
.list-view > article .booking .price-hint span {
  font-weight: normal;
}
.list-view > article .linethrough {
  text-decoration: line-through;
}
.detail-view > article .booking .price-hint {
  width: 100%;
  font-size: 12px;
}
.detail-view > article .booking .price-hint span {
  font-weight: normal;
}
.detail-view > article .linethrough {
  text-decoration: line-through;
}
.seminar-zielgruppe {
  margin-top: 19px;
}
/* tags */
.searchparameter {
  margin-top: 26px;
}
.searchparameter > h3 {
  margin-bottom: 16px;
}
.searchparameter dl {
  margin-bottom: 10px;
  overflow: hidden;
}
.searchparameter dt {
  width: 120px;
  font-weight: 500;
  float: left;
  overflow: hidden;
}
.searchparameter dt:after {
  content: '';
  display: block;
}
.searchparameter dd {
  overflow: hidden;
  vertical-align: middle;
}
.searchparameter .tag {
  display: inline-block;
  padding: 4px 8px;
  border: #DBDADA 1px solid;
  border-radius: 2px;
  margin-bottom: 10px;
  margin-right: 5px;
  -webkit-transition: background-color 0.4s 0.1s;
  -o-transition: background-color 0.4s 0.1s;
  transition: background-color 0.4s 0.1s;
  background-color: transparent;
}
.searchparameter .tag:hover {
  background-color: #DBDADA;
}
.searchparameter dd > a {
  margin-right: 10px;
}
.pagination-result-text {
  width: 100%;
}
.searchparameter .tag a,
.searchparameter dd > a span {
  font-family: 'Iconfont';
  content: 'i';
  margin-left: 5px;
  font-size: 11px;
  vertical-align: top;
  cursor: pointer;
}
.searchparameter .tag a {
  color: #424242;
}
.seminar-teaser .infos dl dt {
  width: 125px;
}
.seminar-teaser.webinar .infos dl dt {
  width: 70px;
}
.seminar-teaser > div.image {
  width: 100%;
  margin-bottom: 15px;
}
.seminar-teaser > div.image img {
  width: 100%;
}
.seminar-teaser > div.infos {
  margin-bottom: 26px;
}
.seminar-teaser > div.infos .newstipp-header {
  margin-bottom: 15px;
}
.seminar-teaser > div.infos .newstipp-header .main-focus {
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 19px;
  text-transform: uppercase;
}
.seminar-teaser > div.infos .newstipp-header .title {
  font-size: 18px;
  line-height: 23px;
}
.seminar-teaser > div.infos .newstipp-content-item {
  display: flex;
  flex-flow: row nowrap;
  font-size: 15px;
  gap: 10px;
  margin-bottom: 0px;
  overflow: unset;
}
.seminar-teaser > div.infos .newstipp-content-item dt {
  color: #424242;
  font-size: 15px;
  font-weight: 500;
  flex-basis: 130px;
  flex-shrink: 0;
  float: unset;
  margin-bottom: 0;
  width: unset;
}
.seminar-teaser > div.infos .newstipp-content-item dd {
  flex-grow: 10;
  flex-basis: 100%;
  margin-bottom: 0;
  overflow: unset;
}
.seminar-teaser > div.infos .newstipp-content-item dd a {
  color: #424242;
  font-weight: normal;
}
.seminar-teaser > div.infos .newstipp-content-item--further-dates {
  font-weight: 500;
  margin-top: 26px;
}
.seminar-teaser > div.infos .newstipp-content-item--further-dates ul {
  padding: 0;
}
.seminar-teaser > div.infos .newstipp-content-item--further-dates ul li {
  list-style: none;
}
.seminar-teaser > div.infos .newstipp-content-item--further-dates ul li a {
  font-weight: normal;
  color: #424242;
}
.seminar-teaser .seminar-info-spezifikation-list {
  flex-flow: row wrap;
}
.seminar-teaser .seminar-info-spezifikation-list .seminar-spezification {
  background-color: #ffffff;
}
h4.seminarnews {
  color: #454661;
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 500;
  line-height: 25px;
  padding: 0;
}
.col-35 .teaser .seminar-teaser dl,
.col-35 .teaser .seminar-teaser dd,
.col-35 .teaser .seminar-teaser dt {
  margin-bottom: 0;
}
.seminar-teaser-header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  min-height: 42px;
  position: relative;
  word-break: break-word;
}
.seminar-teaser-header img {
  position: absolute;
  top: 6px;
}
.seminar-teaser-header .seminar-teaser-title {
  margin-left: 70px;
}
.seminar-teaser-date-tips {
  margin-top: 16px;
}
.seminar-teaser-date-tips dl > dt {
  width: 70px;
}
.seminar-links {
  margin-left: 130px;
  display: flex;
  flex-flow: row wrap;
  gap: 26px;
}
.referent-teaser {
  border-top: 2px solid #c1d7e9;
  margin: 12px 0 0 0;
  overflow: hidden;
  padding: 20px 0 8px 0;
}
.referent-teaser > div.image {
  display: inline-block;
  padding: 6px 0 0 0 !important;
  vertical-align: top;
  width: 130px;
}
.referent-teaser > div.image > img {
  width: 100%;
  height: auto;
  border: 2px solid #FFF;
}
.referent-teaser > div.infos {
  display: inline-block;
  padding-right: 0;
  vertical-align: top;
  width: 74%;
  max-width: calc(100% - 130px);
}
.blue .media > .image-zustaendig > img {
  width: 100%;
  height: auto;
  border: 2px solid #FFF;
}
.media > .image-zustaendig > img {
  width: 100%;
  height: auto;
  border: 1px solid #C1D7E9;
}
.media .image-zustaendig {
  width: 106px;
}
.row.columns-50.contact div.image-svg {
  width: 144px;
}
.row.columns-50.contact div.image-svg > img {
  height: auto;
  width: 144px;
}
/*============  Sidebar  =============*/
/* teaser */
.teaser {
  margin-bottom: 26px;
}
.teaser > h2 {
  margin-bottom: 0;
  padding: 16px 26px 18px;
  font-size: 16px;
}
.teaser > .content {
  padding: 26px;
}
.teaser > .content p:last-child {
  margin-bottom: 0;
}
.teaser > .content > form.contentlogin {
  margin-bottom: 0;
  padding-bottom: 0;
}
.teaser > .content > form > a {
  display: inline-block;
  margin-bottom: 13px;
}
.teaser > .content img {
  max-width: 100%;
  height: auto;
}
.teaser > .content.ce-center .ce-gallery {
  margin: 0 auto;
  padding-bottom: 10px;
}
.teaser > .content.ce-below .ce-gallery {
  padding-top: 20px;
  padding-bottom: 0px;
  margin-top: 0px;
}
.teaser > .content.ce-intext .ce-gallery {
  padding-top: 6px;
}
.teaser.blue > h2 {
  background-color: #7fabd0;
  color: #fff;
}
.teaser.green > h2 {
  background-color: #a5aa15;
  color: #fff;
}
.teaser.midblue .button {
  background-color: #ffffff !important;
  color: #0080bc !important;
}
.teaser.midblue .button:hover,
.teaser.midblue .button:focus {
  background-color: #4da6d0 !important;
  color: #ffffff !important;
}
.teaser.midblue .button.inactive,
.teaser.midblue .button[disabled] {
  cursor: default !important;
  opacity: 0.6 !important;
  pointer-events: none !important;
}
.teaser.midblue .button.inactive:before,
.teaser.midblue .button[disabled]:before {
  display: none !important;
}
footer .teaser.newsletter {
  max-width: 450px;
}
footer .teaser.newsletter a:hover {
  color: #0080bc;
}
.teaser.newsletter {
  display: inline-block;
  margin-bottom: 0;
  margin-top: 26px;
  text-align: left;
}
.teaser.newsletter .content {
  box-sizing: border-box;
  padding: 20px 32px 26px;
}
.teaser.newsletter a,
.teaser.newsletter h2,
.teaser.newsletter p {
  color: #ffffff;
}
.teaser.newsletter h2,
.teaser.newsletter p {
  padding: 0 74px 0 0;
}
.teaser.newsletter h2 {
  font-size: 28px;
  line-height: 1.2;
  margin-bottom: 10px;
  position: relative;
  text-transform: none;
}
.teaser.newsletter h2:after {
  background: url('../Icon/icon-mail-v-ticker.svg') no-repeat center / contain;
  content: '';
  display: block;
  height: 42px;
  position: absolute;
  right: 0;
  top: -3px;
  width: 42px;
}
.teaser.newsletter p {
  font-size: 15px;
  line-height: 1.3;
  margin: 0;
}
.teaser.newsletter p a {
  text-decoration-thickness: 2px;
  text-decoration: underline;
  text-underline-offset: 4px;
}
footer .teaser.newsletter {
  margin-top: 0;
}
footer .col-50:last-child {
  text-align: right;
}
body#home #head-slider + section > .wrapper > .teaser:first-of-type {
  margin-bottom: 52px;
}
/* level 1 */
.submenu {
  list-style: none;
  margin-bottom: 26px;
  padding-left: 0 !important;
}
.submenu > li {
  border-top: 2px solid #C1D7E9;
  font-weight: 500;
  position: relative;
}
.submenu > li div.open {
  position: absolute;
  display: block;
  height: 100%;
  width: 50px;
  right: 0;
  top: 0;
  text-align: center;
  z-index: 1;
  cursor: pointer;
}
.submenu > li div.open:after {
  margin-top: 15px;
  content: '=';
  display: inline-block;
  font-family: 'Iconfont';
  -webkit-transform: rotateZ(0);
  -moz-transform: rotateZ(0);
  -ms-transform: rotateZ(0);
  -o-transform: rotateZ(0);
  transform: rotateZ(0);
  -webkit-transition: -webkit-transform 0.4s;
  -o-transition: -o-transform 0.4s;
  transition: transform 0.4s;
}
.submenu > li > a {
  display: block;
  padding: 12px 12px 12px 26px;
  position: relative;
}
.submenu > li:hover > a {
  background-color: #b8d6f0;
  color: #0080bc;
  -webkit-transition: background-color 0.4s;
  -o-transition: background-color 0.4s;
  transition: background-color 0.4s;
}
.submenu > li.active,
.submenu > li.active.cur {
  background-color: #d8ecfe;
}
.submenu > li.active.cur > a {
  background-color: #b8d6f0;
}
.submenu > li.active div.open:after {
  -webkit-transform: rotateZ(180deg);
  -moz-transform: rotateZ(180deg);
  -ms-transform: rotateZ(180deg);
  -o-transform: rotateZ(180deg);
  transform: rotateZ(180deg);
}
.submenu > li.active > a {
  color: #424242;
}
.submenu > li:last-child {
  border-bottom: 2px solid #C1D7E9;
}
/* level 2 */
.submenu > li > ul {
  list-style: none;
  margin: 0 !important;
  padding: 0 !important;
}
.submenu > li > ul > li {
  color: #424242;
}
.submenu > li > ul > li > a {
  padding: 6px 26px;
  display: block;
}
.submenu > li > ul > li:last-child > a {
  padding-bottom: 12px;
}
.submenu > li.active > ul > li.active > a {
  background-color: #b8d6f0;
}
.submenu > li > ul > li:hover a {
  background-color: #b8d6f0;
  color: #0080bc;
  -webkit-transition: background-color 0.4s;
  -o-transition: background-color 0.4s;
  transition: background-color 0.4s;
}
.marginal-header + .submenu > li:first-child {
  border-top: none;
}
/* News Archive Menu */
form.archive_menu {
  margin-bottom: 26px;
}
form.archive_menu fieldset {
  border-top: 2px solid #C1D7E9;
}
form.archive_menu fieldset:last-child {
  border-bottom: 2px solid #C1D7E9;
}
form.archive_menu fieldset ul {
  list-style: none;
  margin: 0 !important;
  padding: 0 !important;
}
form.archive_menu fieldset ul li {
  padding: 6px 26px;
}
form.archive_menu fieldset ul li ul .reset-filters {
  padding-left: 0;
  padding-right: 0;
}
form.archive_menu fieldset ul li:hover {
  background-color: #b8d6f0;
  -webkit-transition: background-color 0.4s;
  -o-transition: background-color 0.4s;
  transition: background-color 0.4s;
}
form.archive_menu fieldset label {
  color: #0080bc;
  cursor: pointer;
  font-weight: 500;
  height: auto;
}
form.archive_menu fieldset > div.active {
  background-color: #d8ecfe;
  margin: 0;
}
form.archive_menu fieldset legend {
  display: block;
  margin: 0;
  padding: 12px;
}
form.archive_menu fieldset > div {
  margin-bottom: 0;
  position: relative;
}
form.archive_menu fieldset div.open {
  position: absolute;
  display: block;
  height: 100%;
  width: 50px;
  right: 0;
  top: 0;
  text-align: center;
  z-index: 1;
  cursor: pointer;
}
form.archive_menu fieldset div.open:after {
  margin-top: 15px;
  content: '=';
  display: inline-block;
  font-family: 'Iconfont';
  -webkit-transform: rotateZ(0);
  -moz-transform: rotateZ(0);
  -ms-transform: rotateZ(0);
  -o-transform: rotateZ(0);
  transform: rotateZ(0);
  -webkit-transition: -webkit-transform 0.4s;
  -o-transition: -o-transform 0.4s;
  transition: transform 0.4s;
}
form.archive_menu fieldset > div.active div.open:after {
  -webkit-transform: rotateZ(180deg);
  -moz-transform: rotateZ(180deg);
  -ms-transform: rotateZ(180deg);
  -o-transform: rotateZ(180deg);
  transform: rotateZ(180deg);
}
form.archive_menu input.custom[type="checkbox"] + label {
  display: block;
  padding-left: 22px;
  position: relative;
}
form.archive_menu input.custom[type="checkbox"] + label:before {
  left: 0;
  position: absolute;
  top: 6px;
}
/*============  Lightbox  =============*/
.mfp-close:before {
  content: '';
}
/*============  Booking  =============*/
.booking.blue .icon-loading {
  font-size: 30px;
  width: 35px;
  height: 35px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}
.booking.blue {
  min-height: 665px;
  margin-bottom: 48px;
}
.progress {
  list-style: none;
  display: table;
  width: 100%;
  background-color: #97bddb;
  text-transform: uppercase;
  font-size: 18px;
  color: #fff;
}
.progress > li {
  display: table-cell;
  padding: 20px 42px;
  position: relative;
  font-weight: 500;
  text-align: center;
}
.progress > li > a {
  color: #fff;
}
.progress.no-center > li {
  text-align: left;
}
.progress > li:first-child {
  padding-left: 26px;
}
.progress > li:after {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 32px 0 32px 29px;
  border-color: transparent transparent transparent #97bddb;
  content: '';
  top: 0;
  right: -29px;
  z-index: 1;
}
.progress > li:last-child:after {
  content: none;
}
.progress > li.before3 {
  background-color: #97bddb;
}
.progress > li.before3:after {
  border-left-color: #97bddb;
}
.progress > li.before2 {
  background-color: #73a5cd;
}
.progress > li.before2:after {
  border-left-color: #73a5cd;
}
.progress > li.before1 {
  background-color: #5590c1;
}
.progress > li.before1:after {
  border-left-color: #5590c1;
}
.progress > li.on {
  background-color: #3b7fb6;
}
.progress > li.on:after {
  border-left-color: #3b7fb6;
}
#registerUserFromBookingLogin {
  margin-top: 26px;
}
.progress + div {
  position: relative;
  padding: 26px;
}
.booking-wrapper {
  width: 533px;
  margin: 0 auto;
  position: relative;
}
.booking-wrapper div {
  margin-bottom: 10px;
}
.booking-wrapper h2 {
  margin-bottom: 26px;
}
.booking-wrapper .ajax-buttons {
  margin-top: 26px;
}
.form-line {
  position: relative;
  font-size: 0;
  overflow: hidden;
  margin-bottom: 15px;
}
.form-line.info > p {
  display: inline-block;
}
.form-line > * {
  font-size: 15px;
}
.form-line span.hint {
  color: #bac013;
}
.form-line > span.hint {
  float: right;
  font-size: 13px;
  width: 60%;
}
.form-line label:first-child,
.form-line.info p:first-child {
  width: 40%;
  display: inline-block;
  vertical-align: top;
}
.form-line label > span.hint {
  width: auto;
}
.form-line input[type="email"],
.form-line input[type="text"],
.form-line input[type="textarea"],
.form-line input[type="password"],
.form-line input[type="number"],
.form-line input[type="tel"],
.form-line.info p:last-child {
  width: 60%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  vertical-align: top;
}
.form-line input[type="email"],
.form-line input[type="text"],
.form-line input[type="password"],
.form-line input[type="number"],
.form-line input[type="tel"] {
  font-size: 13px;
}
.form-line textarea {
  width: 59%;
  font-size: 13px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  vertical-align: top;
}
.form-line .error:hover + p.error-note,
.form-line .parsley-error + ul.parsley-errors-list li {
  background-color: #c9282d;
}
.form-line .error:hover,
.form-line .parsley-error:hover {
  border-color: #c9282d;
}
.mandatory-fields span {
  display: block;
}
header .mandatory-fields {
  margin-top: 26px;
}
.form-line p.error-note {
  float: right;
  width: 60%;
}
.form-line ul.parsley-errors-list {
  float: right;
  list-style: none;
  margin: 0;
  width: 60%;
}
p.error-note,
ul.parsley-errors-list li,
p.success-note {
  background-color: #f32837;
  box-sizing: border-box;
  color: #ffffff;
  font-size: 15px;
  margin-top: 10px;
  padding: 3px 5px;
  transition: background-color 0.3s;
}
p.error-note .error {
  display: block;
}
p.success-note {
  background-color: #BAC013;
  margin-bottom: 10px;
  margin-top: 0;
}
/* last step */
.row.confirm-client {
  margin-bottom: 26px;
}
.confirm-client .infos dl {
  margin-bottom: 0;
}
.confirm-client > div {
  padding-left: 0;
  padding-top: 0;
  position: relative;
}
.confirm-client > div:last-child {
  padding-right: 0;
}
.confirm-client .form-line input[type="text"],
.confirm-client .form-line input[type="email"],
.confirm-client .form-line input[type="tel"],
.confirm-client .form-line input[type="password"],
.confirm-client .form-line textarea {
  width: 55%;
  margin-left: 5px;
}
.confirm-client .infos {
  background-color: #4687bb;
  padding: 26px;
  color: #fff;
  margin-top: 26px;
  margin-right: 5%;
}
.confirm-client .infos h4,
.confirm-client .infos h3 {
  color: #fff;
}
.confirm-client .form-line p.error-note {
  margin-right: 5%;
  width: 55%;
}
.confirm-client > div > div > p {
  margin-bottom: 0;
}
.agb-wrapper {
  margin-bottom: 0;
}
.confirm > div > .error-note,
.agb-wrapper .error-note {
  margin-right: 2.5%;
}
.agb {
  display: none;
}
.row.participant {
  border-top: 2px solid #b7d2e8;
  padding: 26px 0;
}
.row.participant > div {
  padding-left: 0;
  padding-top: 0;
}
.row.participant > div:last-child:not(:first-child) {
  padding-right: 0;
}
.row.participant .form-line {
  width: 100%;
}
/*============  Booking - dynamic fields  =============*/
#booking-dynamic-fields-teilnehmer {
  display: none;
}
#booking-dynamic-fields-teilnehmer.js-active {
  display: block;
}
.booking-dynamic-fields,
.booking .booking-dynamic-fields {
  margin: 25px 0;
}
.booking-dynamic-fields .form-line.error input.custom[type="checkbox"] + label:before {
  border-color: #f32837;
}
.booking-dynamic-fields .form-line p.error-note {
  float: none;
  width: auto;
}
.booking-dynamic-fields-title {
  font-weight: bold;
}
.booking-dynamic-fields-title,
.booking .booking-dynamic-fields-title {
  margin-bottom: 15px;
}
.booking-dynamic-fields-columns {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.booking-dynamic-fields-columns,
.booking .booking-dynamic-fields-columns {
  margin-bottom: 15px;
}
.booking-dynamic-fields-column {
  box-sizing: border-box;
  width: 50%;
}
.booking-dynamic-fields-column,
.booking .booking-dynamic-fields-column {
  margin-bottom: 0;
}
.booking-dynamic-fields-column:first-child {
  padding-right: 15px;
}
.booking-dynamic-fields-column:last-child {
  padding-left: 15px;
}
.booking-dynamic-fields-row + .booking-dynamic-fields-row {
  border-top: 1px solid #DBDADA;
  margin-top: 15px;
  padding-top: 15px;
}
.booking-dynamic-fields-row,
.booking .booking-dynamic-fields-row {
  margin-bottom: 0;
}
.booking-dynamic-field-wrap + .booking-dynamic-field-wrap {
  margin-top: 10px;
}
.booking-dynamic-field-wrap,
.booking .booking-dynamic-field-wrap {
  margin-bottom: 0;
}
/*-----  End of Modules  ------*/
/*=====================================
=            Footer Styles            =
=====================================*/
footer {
  background-color: #0080bc;
  margin: 0 auto;
  padding-top: 26px;
  min-width: 993px;
  position: relative;
  width: 100%;
}
footer > .wrapper {
  margin-bottom: 26px;
}
footer h4 {
  color: #fff;
  font-size: 15px;
  text-transform: uppercase;
}
footer h4 + h4 {
  margin-top: 30px;
}
footer p {
  color: #fff;
  font-size: 14px;
}
footer p span {
  padding: 3px 0 5px 0;
}
footer p span:first-of-type {
  background: url(../Icon/phone.png) no-repeat 0 0;
  padding-left: 36px;
  display: inline-block;
}
footer p span.pipe {
  padding-left: 4px;
  padding-right: 4px;
}
footer a {
  color: #ffffff;
}
footer a:hover {
  color: #83b0d4;
}
/*========  Footer bottom bar  =======*/
footer nav {
  background-color: #1968A8;
  font-size: 14px;
  height: 68px;
  width: 100%;
}
footer nav .wrapper {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 25px;
  position: relative;
  top: 25px;
}
footer nav .wrapper div {
  position: absolute;
  right: 26px;
  top: 0;
}
footer nav .wrapper a {
  margin-left: 20px;
  text-transform: uppercase;
}
footer nav .wrapper span {
  color: #FFF;
  text-align: left;
}
/*-----  End of Footer Styles  ------*/
/*=====================================
=       Interactive Map Styles        =
=====================================*/
.map-text {
  display: none;
  background-color: #0080bc;
  color: #fff;
  width: 570px;
  padding: 26px;
}
.map-text h3 {
  color: #fff;
  padding-right: 36px;
}
.map-text p {
  margin-bottom: 0.8em;
}
.map-text a {
  color: #fff;
}
.map-dot {
  cursor: pointer;
}
.map-dot:hover {
  fill: #9da400;
}
.interactive-map {
  position: relative;
  margin: 26px 0;
}
.interactive-map > svg {
  display: block;
  margin: 0 auto;
}
.overlay .map-text {
  display: inline-block;
}
/*=====================================
=              Overlay                =
=====================================*/
.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.4);
  z-index: 10;
}
.overlay {
  text-align: center;
}
.overlay:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.overlay-content {
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  position: relative;
}
.overlay-close {
  display: block;
  width: 18px;
  height: 18px;
  background: transparent url(../Icon/close.png) no-repeat 0 0 / 18px 18px;
  position: absolute;
  border-radius: 0;
  border: 0;
  right: 26px;
  top: 26px;
  cursor: pointer;
}
.overlay-close:before {
  content: none;
}
/*=====================================
	News
=====================================*/
.news-filter {
  background-color: #FFF;
  border-top: 2px solid #C1D7E9;
  padding: 5px 10px 0;
}
.news-filter:last-child {
  border-bottom: 2px solid #C1D7E9;
}
.news-filter ul {
  display: none;
  list-style: none;
  padding-bottom: 10px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid blue;
}
.news-filter.on {
  background-color: #ECF5FD;
}
.news {
  position: relative;
}
.news > a.news-overview {
  margin-bottom: 13px;
}
h2 + .news > a.news-overview,
h3 + .news > a.news-overview {
  position: absolute;
  right: 0;
}
h2 + .news > a.news-overview {
  top: -37px;
}
h3 + .news > a.news-overview {
  top: -27px;
}
#home .container > .row:not(.publications) > div > h2:first-child + .news > a.news-overview,
#home .container.color-right > .row:not(.publications) > div > div > h2:first-child + .news > a.news-overview,
#home .container.color-left > .row:not(.publications) > div > div > h2:first-child + .news > a.news-overview {
  top: -60px;
}
.result-per-page-and-sorting-wrapper {
  display: flex;
  gap: 5px;
}
.result-per-page-and-sorting-wrapper .result-per-page-wrapper,
.result-per-page-and-sorting-wrapper .sorting-wrapper {
  display: flex;
  flex-direction: column;
}
@media (max-width: 500px) {
  .result-per-page-and-sorting-wrapper {
    flex-direction: column;
  }
}
.news.slider .col-50:first-child img {
  max-width: 100%;
  height: auto;
  border: 1px solid #c1d7e9;
}
.news .list-view .row {
  margin-bottom: 14px;
  overflow: hidden;
}
.news .list-view .row > div.image {
  padding-right: 0;
}
.news .list-view .row > div.image img {
  margin-top: 6px;
  border: 1px solid #c1d7e9;
}
.news .list-view .row .infos p,
.news .content-slider .row .infos p {
  display: inline;
}
.news .list-view .row .infos ul,
.news .content-slider .row .infos ul {
  margin-bottom: 0;
}
.news .tx-pagesteaser .page .abstract p {
  display: inline;
}
.news .detail-view img {
  border: 1px solid #c1d7e9 !important;
  box-sizing: border-box;
  max-width: 100%;
  height: auto;
}
.news .detail-view a.button.back {
  margin-top: 7px;
}
.news .detail-view .ce-gallery {
  max-width: 270px;
}
.tx-solr .news > .row > .col-65 {
  padding-left: 0 !important;
}
.tx-solr .news > .row > .col-65 .infos {
  width: 100%;
}
.tx-solr .news > .row .col-65.infos.news-list {
  width: 65%;
}
.tx-solr .news > .row .col-35.news-list-image {
  padding: 0;
}
.tx-solr .news > .row .news-list-image img {
  border: 1px solid #c1d7e9;
}
/* seminars on news detail */
.col-35 .teaser .content ol > li > div.seminar-teaser .infos dl + div {
  margin-top: 16px;
}
.col-35 .teaser .content ol > li > div.seminar-teaser .infos > div > p {
  margin-bottom: 0;
}
/*=====================================
	Project Teaser
=====================================*/
.project-teaser {
  margin-bottom: 26px;
}
.project-teaser.layout-default .project-teaser-element {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.project-teaser.layout-default .project-teaser-element .project-teaser-image {
  width: 35%;
}
.project-teaser.layout-default .project-teaser-element .project-teaser-info {
  width: 100%;
}
.project-teaser.layout-default .project-teaser-element .project-teaser-info p,
.project-teaser.layout-default .project-teaser-element .project-teaser-info a {
  display: inline;
}
.project-teaser.layout-default .project-teaser-element.has-image .project-teaser-info {
  padding-left: 26px;
  width: 65%;
}
.project-teaser .project-teaser-element + .project-teaser-element {
  margin-top: 5px;
}
.project-teaser .button {
  margin-bottom: 0;
}
.project-teaser.teasercolor-1 {
  background-color: #bdd7ee;
}
.project-teaser.teasercolor-1 h3 a {
  color: #006fb4;
}
.project-teaser.teasercolor-1 h3 a:hover,
.project-teaser.teasercolor-1 h3 a:focus {
  color: rgba(0, 111, 180, 0.3);
}
.project-teaser.teasercolor-2 {
  background-color: rgba(197, 224, 180, 0.7);
}
.project-teaser.teasercolor-2 h3 a {
  color: #548235;
}
.project-teaser.teasercolor-2 h3 a:hover,
.project-teaser.teasercolor-2 h3 a:focus {
  background-color: rgba(197, 224, 180, 0.3);
}
.project-teaser.teasercolor-3 {
  background-color: rgba(239, 209, 189, 0.7);
}
.project-teaser.teasercolor-3 h3 a {
  color: #c55a11;
}
.project-teaser.teasercolor-3 h3 a:hover,
.project-teaser.teasercolor-3 h3 a:focus {
  background-color: rgba(239, 209, 189, 0.3);
}
.project-teaser.teasercolor-4 {
  background-color: rgba(255, 242, 204, 0.7);
}
.project-teaser.teasercolor-4 h3 a {
  color: #7030a0;
}
.project-teaser.teasercolor-4 h3 a:hover,
.project-teaser.teasercolor-4 h3 a:focus {
  background-color: rgba(255, 242, 204, 0.3);
}
.project-teaser.teasercolor-5 {
  background-color: #d8ecfe;
}
.project-teaser.teasercolor-5 h3 a {
  color: #006fb4;
}
.project-teaser.teasercolor-5 h3 a:hover,
.project-teaser.teasercolor-5 h3 a:focus {
  background-color: rgba(216, 236, 254, 0.6);
}
.project-teaser.teasercolor-6 {
  background-color: #b8d6f0;
}
.project-teaser.teasercolor-6 h3 a {
  color: #006fb4;
}
.project-teaser.teasercolor-6 h3 a:hover,
.project-teaser.teasercolor-6 h3 a:focus {
  background-color: rgba(184, 214, 240, 0.6);
}
.project-teaser.teasercolor-7 {
  background-color: #62b8e8;
}
.project-teaser.teasercolor-7 h3 a {
  color: #006fb4;
}
.project-teaser.teasercolor-7 h3 a:hover,
.project-teaser.teasercolor-7 h3 a:focus {
  background-color: rgba(98, 184, 232, 0.6);
}
.project-teaser.teasercolor-8 {
  background-color: #dbdada;
}
.project-teaser.teasercolor-8 h3 a {
  color: #006fb4;
}
.project-teaser.teasercolor-8 h3 a:hover,
.project-teaser.teasercolor-8 h3 a:focus {
  background-color: rgba(219, 218, 218, 0.6);
}
.project-teaser.project-search-result {
  padding: 26px;
}
/*=====================================
	Powermail
=====================================*/
.confirmation dl.powermail_all {
  font-size: 0;
  overflow: hidden;
  position: relative;
}
.confirmation dl.powermail_all > * {
  font-size: 15px;
}
.confirmation dl.powermail_all dt {
  display: inline-block;
  margin-bottom: 3px;
  vertical-align: top;
  width: 40%;
}
.confirmation dl.powermail_all dd {
  display: inline-block;
  margin-bottom: 3px;
  vertical-align: top;
  width: 60%;
}
.confirmation .buttons {
  font-size: 0;
  padding-top: 17px;
}
.confirmation .buttons > * {
  font-size: 13px;
}
.confirmation .buttons .form-line {
  display: inline-block;
  vertical-align: top;
  width: 50%;
}
.confirmation .buttons .form-line.forward {
  text-align: right;
  vertical-align: top;
}
.powermail_fieldwrap_custom_text_p.layout3 {
  margin-bottom: 0;
}
.powermail_fieldwrap_custom_text_p.layout3 .powermail_field {
  width: 100%;
}
.powermail_fieldwrap_custom_text_p.layout3 .powermail_field p {
  font-size: 15px;
  width: 100%;
}
.powermail_fieldwrap_custom_text_p_01.layout3 {
  margin-bottom: 0;
}
.powermail_fieldwrap_custom_text_p_01.layout3 .powermail_field {
  width: 100%;
}
.powermail_fieldwrap_custom_text_p_01.layout3 .powermail_field p {
  font-size: 15px;
  width: 100%;
}
/*=====================================
	TYPO3
=====================================*/
.typo3-message > * {
  line-height: 0;
}
.typo3-message p {
  line-height: 24px;
}
.typo3-message.message-ok {
  color: #424242;
}
.typo3-message.message-error {
  color: #424242;
}
.tx-dreipc-mejs button:before,
.quick-search button:before {
  content: none;
}
.teaser h3.hide {
  display: none;
}
.teaser form {
  position: relative;
  padding-bottom: 26px;
}
.teaser form .form-line + .submit {
  right: 0;
  position: absolute;
}
.teaser form.contentlogin button.submit.logout {
  display: block;
  width: 100%;
}
.teaser form.contentlogin div.link {
  display: none !important;
}
.teaser form.contentlogin div.send {
  width: 100% !important;
}
.teaser form.contentlogin div.send button {
  display: block;
  width: 100%;
}
@media screen and (max-width: 1295px) {
  .teaser .form-line label:first-child {
    display: block;
    height: auto;
    width: 100%;
  }
  .teaser .form-line input {
    display: block;
    width: 100%;
  }
}
header #femanager_field_agreement_email_use_wrapper {
  margin-top: 26px;
}
header #femanager_field_agreement_email_use_registration_wrapper {
  margin-top: 26px;
}
#agreement-email-use-guest-wrapper {
  margin: -16px 0 30px 0;
}
#newsletter-registration-wrapper {
  margin-bottom: 26px;
}
#ajax-container > #ajax-section #next-step-print-info {
  margin-bottom: 0;
  margin-top: 40px;
}
.femanager_flashmessages > div {
  background-position: 10px 5px;
  margin: 15px;
}
/*=====================================
	Modal popup for survey
=====================================*/
.modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: #000000;
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}
/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 40%;
  /* Could be more or less, depending on screen size */
  min-width: 480px;
}
/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}
.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
/*=====================================
	Mediaelement JS
=====================================*/
.tx-dreipc-mejs .player {
  border: 1px solid #c1d7e9;
}
/*=====================================
	figcaption
=====================================*/
.ce-gallery figure {
  position: relative;
}
.ce-gallery figcaption {
  background: rgba(0, 0, 0, 0.35);
  bottom: 0;
  box-sizing: border-box;
  color: #ffffff;
  left: 0;
  padding: 3px 6px;
  position: absolute;
  width: 100%;
  line-height: 1.3;
  font-size: 10px;
}
/*=====================================
	Seminar not available
=====================================*/
.seminar--not-available {
  height: 50vh;
}
@media screen and (max-width: 985px) {
  .submenu > li > a {
    padding: 12px;
  }
}
